import React, {Component} from 'react';
import {Formik, Field} from 'formik';
import ErrorMessage from '../ErrorMessage';
import {BASE_URL} from '../../constants';
import swal from 'sweetalert';
import * as yup from 'yup';
import {Button, Divider, Grid, Header, Icon, Search, TextArea, Form, Dropdown, Checkbox} from 'semantic-ui-react';
import {phoneValidate, textValidate, alphaNumericValidate, numberValidate} from '../../helpers';
import {createCar, updateCar, submitFormData} from '../../api/carApi';
import {getPartners} from '../../api/partnerApi';
import history from '../../history';

class CarForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			partners: []
		};
	}

	componentDidMount() {
		var params = {};
		params['q[company_type_cont]'] = 'vehicle rental';
		this.fetchDetails(params);
	}

	fetchDetails(params) {
		getPartners(params).then((response) => {
			this.setState({
				partners: response.data.partners
			});
		});
	}

	// uploadImages = (id) => {
	// 	const data = new FormData();
	// 	Array.from(document.querySelector('[type=file]').files).map((v, index) => {
	// 		data.append(`images[${index}]`, v);
	// 	});

	// 	fetch(`${process.env.REACT_APP_BASE_URL}/api/packages/${id}/images`, {
	// 		method: 'PUT',
	// 		body: data
	// 	});
	// };

	render() {
		const {car} = this.props.location.state != null ? this.props.location.state : {car: {}};
		const {partners} = this.state;
		const carDetails = {
			car_type: car.car_type,
			price: car.price,
			no_of_seats: car.no_of_seats,
			details: car.details,
			image: car.image,
			partner_id: car.partner_id,
			has_carrier: car.has_carrier
		};

		const CarSchema = yup.object().shape({
			car_type: yup.string().required('Required'),
			price: yup.string().required('Required'),
			// price: yup.number().typeError('Not a valid amount').min(0, 'should be greater than 0').required('Required'),
			no_of_seats: numberValidate(yup),
			details: yup.string().required('Required')
		});

		return (
			<div className='container'>
				<div className='card'>
					<div className='card-body'>
						{/* <form onSubmit={this.uploadImages}>
							<input id='package_images' name='package[images[]]' type='file' multiple />
							<input type='submit' />
						</form> */}
						<Formik
							initialValues={carDetails}
							validationSchema={CarSchema}
							onSubmit={(values, {setSubmitting}) => {
								submitFormData('admin/cars', car.idx, {
									...values,
									image: document.querySelector('[type=file]').files
								})
									.then((response) => {
										setSubmitting(false);
										swal({
											title: 'Car created or updated Success!',
											text: response.data.message,
											icon: 'success',
											button: 'continue!'
										}).then((value) => {
											history.push(`/admin/cars`);
										});
									})
									.catch((error) => {
										setSubmitting(false);
										console.log(' Car create error', error);
									});
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setFieldValue
								/* and other goodies */
							}) => {
								return (
									<form onSubmit={handleSubmit}>
										<div className='input-section'>
											<div className='row'>
												<div className='col-12 col-md-6'>
													<h3 className='title'>Add Car</h3>
													<div className='text-center'>
														{values.image && <img src={values.image} />}
														{!values.image && <i className='huge car icon' />}
													</div>
													<label for='file'>Upload Image</label>
													<input
														id='image'
														name='image'
														type='file'
														onChange={(event) => {
															var file = event.target.files[0];
															var reader = new FileReader();
															reader.onload = function(item) {
																setFieldValue('image', item.target.result);
															};
															reader.readAsDataURL(file);
														}}
														className='form-control'
														multiple
													/>
													<ErrorMessage name='image' />

													<div className='field-box'>
														<label htmlFor=''>Details</label>
														<TextArea
															className='form-control'
															name='details'
															placeholder='detail'
															onBlur={handleBlur}
															onChange={(e, data) => {
																setFieldValue(`details`, e.target.value);
															}}
															value={values.details}
														/>
														<ErrorMessage name='details' />
													</div>
												</div>
												<div className='col-12 col-md-6'>
													<div className='field-box'>
														<Form.Field>
															<label>Car Type</label>
															<Form.Input
																fluid
																icon='car icon'
																iconPosition='left'
																name='car_type'
																onBlur={handleBlur}
																onChange={handleChange}
																value={values.car_type}
															/>
														</Form.Field>

														<ErrorMessage name='car_type' />
													</div>
													<div className='field-box'>
														<Form.Field>
															<label>Price</label>
															<Form.Input
																fluid
																icon='money icon'
																iconPosition='left'
																name='price'
																onBlur={handleBlur}
																onChange={handleChange}
																value={values.price}
															/>
														</Form.Field>

														<ErrorMessage name='price' />
													</div>
													<div className='field-box'>
														<Form.Field>
															<label>Number of seats</label>
															<Form.Input
																fluid
																icon='users icon'
																iconPosition='left'
																name='no_of_seats'
																type='number'
																className=''
																onBlur={handleBlur}
																onChange={handleChange}
																value={values.no_of_seats}
																placeholder='number of seats'
															/>
														</Form.Field>
														<ErrorMessage name='no_of_seats' />
													</div>
													<div className='field-box'>
														<label>Select Partner</label>
														<Dropdown
															className='icon btn-dropdown'
															name='partner_id'
															icon='user icon'
															iconPosition='left'
															placeholder='Select Partner'
															onBlur={handleBlur}
															onChange={(e, data) => {
																setFieldValue(`partner_id`, data.value);
															}}
															value={values.partner_id}
															fluid
															search
															selection
															options={partners.map(function(partner) {
																name = partner.first_name + ' ' + partner.last_name + ' (' + partner.company_name + ')';
																return {
																	key: partner.id,
																	value: partner.id,
																	text: name
																};
															})}
														/>
													</div>
												</div>
												<div className='col-12 col-md-6'>
													<div className='field-box'>
														<Checkbox
															name='has_carrier'
															className=''
															onBlur={handleBlur}
															onChange={(event, data) => {
																setFieldValue('has_carrier', data.checked);
															}}
															checked={values.has_carrier}
															label={{
																children: 'has carrier'
															}}
														/>
														<ErrorMessage name='has_carrier' />
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-12 col-md-6' />

												<div className='col-12 col-md-6' />
											</div>

											<div className='row'>
												<div className='col-12 col-md-6' />
											</div>
										</div>

										<div className='text-center'>
											<button
												className='btn btn-secondary m-2'
												type='submit'
												disabled={isSubmitting}
											>
												Submit
											</button>
										</div>
									</form>
								);
							}}
						</Formik>
					</div>
				</div>
			</div>
		);
	}
}
export default CarForm;
