export function abouts(){
    return [
            {
                idx: "", 
                question: "Why Rent a car in Nepal with us ?", 
                answer: `   We believe Car Rental industry in Nepal requires a managed way to provide service and a secure system to place order and to pay digitally. 
                            VisitAllNepal.com Car Rental is introducing itself as a professionally managed Nepal’s largest Car Rental company based in Kathmandu. 
                            We have a large number of vehicle for such as Sedan Car, SUV car, Jeep including 4 wheels drive (4WD), van (big & mini) and Bus (big & mini). 
                            The major benefit of renting a car with us is our brand value and we look forward to manage and build good will and trust for our customers .  
                            Therefore we offer car rental in Kathmandu, and other major cities (places) in Nepal.  
                            The company is specialized in local car rental service in Nepal.`,
                active: true
            },

            {
                idx: "", 
                question: "Car Rental Features:", 
                answer: `   A primary goal of our car rental in Nepal is to achieve the highest standards of quality service without any compromise. 
                            Our objective is to continually improve the car rental services in Nepal, while offering our customers a cost effective services. 
                            Quality car rental service performance is one of the cornerstones of our company culture, and is considered a personal responsibility of all employees to maintain quality at the highest level. 
                            Our client feels our service with hassle-free experience. We offer the best price for the online booking customer. 
                            Our Price is most competitive based on “traveling with comfort”. Our drivers highly experienced and well-trained with support staff to care your baggage and required assistance. In addition we mostly select local driver because he can helps you to observe and see the local values and culture of the commitment to diversity while traveling with your family or friends or individual.
                            We offer luxury, deluxe, and cheap & budget car rental services in Nepal for a few hours to few weekly. You can enjoy our different types of vehicles rental service like car, jeep, van, bus, coaster, 4WD and minibus. Our car rental service is available in Kathmandu, Pokhara, Chitwan, Lumbini, Nagarkot, Bardia and other cities of Nepal.`
                ,active: false
            }
        ]
}