import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import swal from 'sweetalert';
import moment from 'moment';
import {ifNotZero, pick} from '../../helpers';
import {Badge} from '../shared';
import {Accordion, Button} from 'semantic-ui-react';
import {getBookingDetails} from '../../api/flightApi';
import {fetchTicket} from '../../api/flightApi';
import {downloadTicket} from '../../helpers';
import PassengerDetails from '../flights/PassengerDetails';

class BookingDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			booking: {
				passengers: []
			}
		};
	}
	componentDidMount() {
		this.fetchBooking(this.props.match.params.ruid);
	}

	fetchBooking(ruid) {
		getBookingDetails(ruid).then((response) => {
			this.setState({
				booking: response.data.departing_flight,
				loading: false
			});
		});
	}

	destroyBooking = (id) => {
		// deleteBooking(id)
		// .then((response) => {
		// 	swal({
		// 		title: 'Flight Booking deleted!',
		// 		text: `this flight booking is deleted`,
		// 		icon: 'success',
		// 		button: 'Continue!'
		// 	});
		// 	history.go();

		// })
		// .catch((error) => {
		// 	swal({
		// 		title: 'Flight Booking Delete error',
		// 		text: 'Something went wrong. please try again or contact us',
		// 		icon: 'error',
		// 		button: 'Continue!'
		// 	});
		// })

		swal({
			title: 'Are you sure?',
			text: 'Once delete, your flight booking will be deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true
		}).then((willDelete) => {
			if (willDelete) {
				deleteBooking(id).then((response) => {
					swal('this flight booking is deleted', {
						icon: 'success'
					});
					history.go();
				});
			} else {
				swal('Your flight booking is not deleted yet');
			}
		});
	};

	download = (idx) => {
		fetchTicket(idx).then((response) => {
			this.setState({
				loading: false
			});
			downloadTicket(response.data);
		});
	};

	render() {
		const {booking, loading} = this.state;

		const flightDetails = pick(booking, ['flight_id', 'airline', 'flight_no', 'class_code', 'free_baggage']);
		const bookingDates = pick(booking, [
			'booking_date_time',
			'departure_flight_time',
			'reporting_time',
			'created_at'
		]);
		const bookingDetails = pick(booking, ['pnr_no', 'refundable']);

		return (
			<React.Fragment>
				<div className='ui segment container'>
					<div className='ui internally celled stackable grid section-layout'>
						<div className='row'>
							<div className='eight wide column section'>
								<h3 className='ui header'> Flight Details </h3>
								<div className='ui grid'>
									{Object.entries(flightDetails).map(([key, value]) => (
										<div className='row'>
											<div className='eight wide column'>{key.titleize()}:</div>
											<div className='eight wide column'>{value}</div>
										</div>
									))}
								</div>
							</div>

							<div className='eight wide column section'>
								<h3 className='ui header'> Booking Details </h3>
								<div className='ui grid'>
									{Object.entries(bookingDates).map(([key, value]) => (
										<div className='row'>
											<div className='eight wide column'>{key.titleize()}:</div>
											<div className='eight wide column'>
												{moment(value).format('D MMMM, YYYY')}
											</div>
										</div>
									))}

									{Object.entries(bookingDetails).map(([key, value]) => (
										<div className='row'>
											<div className='eight wide column'>{key.titleize()}:</div>
											<div className='eight wide column'>{value}</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='column section'>
								<h3 className='ui header'> Passenger Details </h3>
								<PassengerDetails passengers={booking.passengers} />
							</div>
						</div>
					</div>

					<div className='text-center'>
						{booking.status === 'verified' && (
							<Button
								primary
								loading={loading}
								className='btn btn-primary btn-large '
								onClick={() => this.download(booking.booking_transaction.idx)}
							>
								Download ticket
							</Button>
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default BookingDetails;
